
import {
    CalendarOutlined,
    DownloadOutlined
} from '@ant-design/icons-vue';
import {renderUnit} from "@/utils/common"

export default {
    name: "AccountExpressInvoice",
    components: {
        CalendarOutlined,
        DownloadOutlined
    },
    data() {
        return {
            url: `/user/express_invoice/list`,
            where: { },
            selection: [],
            columns: [{
                    title: this.$t("express_invoice.fields.po_number"),
                    dataIndex: "po_number",
                    sorter: true,
                    width: 200,
                    slots: {
                        customRender: "po_number",
                    }
                },
                {
                    title: this.$t("express_invoice.fields.order_charge"),
                    dataIndex: "order_charge",
                    sorter: true,
                    width: 100,
                    customRender({
                        record
                    }) {
                        return record.order_charge > 0 ? record.order_charge : '';
                    }
                },
                {
                    title: this.$t("express_invoice.fields.service_charge"),
                    dataIndex: "service_charge",
                    sorter: true,
                    width: 100,
                    customRender({
                        record
                    }) {
                        return record.service_charge > 0 ? record.service_charge : '';
                    }
                }, 
                {
                    title: this.$t("express_invoice.fields.master_tracking_number"),
                    dataIndex: "master_tracking_number",
                    sorter: true,
                    width: 200
                },
                {
                    title: this.$t("express_invoice.fields.child_tracking_number"),
                    dataIndex: "tracking_number",
                    sorter: true,
                    width: 200
                },
                {
                    title: this.$t("express_invoice.fields.package_dim"),
                    sorter: true,
                    width: 220,
                    customRender({
                        record
                    }) {
                        return `${record.package_length} x ${record.package_width} x ${record.package_height} ${renderUnit(record, 'dims_unit')} - ${record.package_weight || 0} ${renderUnit(record, 'weight_unit')}`;
                    }
                },
                {
                    title: this.$t("express_invoice.fields.invoice_dim"),
                    sorter: true,
                    width: 220,
                    customRender({
                        record
                    }) {
                        return `${record.dim_length} x ${record.dim_width} x ${record.dim_height} ${renderUnit(record, 'dims_unit')} - ${record.dim_weight || 0} ${renderUnit(record, 'weight_unit')}`;
                    }
                },
                {
                    title: this.$t("express_invoice.fields.charge_name"),
                    dataIndex: "charge_name",
                    sorter: true
                },
                {
                    title: this.$t("express_invoice.fields.single_charge"),
                    dataIndex: "invoice_charge",
                    sorter: true,
                    width: 100
                },
                {
                    title: this.$t('express_invoice.fields.order_date'),
                    dataIndex: 'order_date',
                    width: 120,
                    sorter: true,
                    align: 'center'
                },
                {
                    title: this.$t('express_invoice.fields.invoice_date'),
                    dataIndex: 'invoice_date',
                    width: 120,
                    sorter: true,
                    align: 'center'
                }
            ],
            // 导出loading状态
            exportLoading: false
        };
    },
    mounted() {},
    methods: {
        /**
         * 刷新表格
         */
        reload() {
            this.selection = [];
            this.$refs.table.reload({
                page: 1
            });
        },
        /**
         * 重置搜索
         */
        reset() {
            this.where = {};
            this.$nextTick(() => {
                this.reload();
            });
        },
        /**
         * 导出Excel
         */
        exportExcel() {
            this.exportLoading = true;

            this.$http.get('/user/express_invoice/export', { params: this.where }).then(res => {
                this.exportLoading = false;

                if (res.data.code === 0) {
                    const blob = this.base64ToBlob(res.data.data, 'application/vnd.ms-excel');
                    const fileName = `Invoices-${new Date().getTime()}.xls`;

                    if ("download" in document.createElement("a")) {
                        // 非IE下载
                        const el = document.createElement("a");

                        el.download = fileName;
                        el.style.display = "none";
                        el.href = URL.createObjectURL(blob);
                        document.body.appendChild(el);

                        el.click();

                        URL.revokeObjectURL(el.href);
                        document.body.removeChild(el);
                    } else {
                        // IE10+下载
                        navigator.msSaveBlob(blob, fileName);
                    }
                } else {
                    this.$message.error(res.data.msg);
                }
            }).catch(e => {
                this.exportLoading = false;
                this.$message.error(e.message);
            });
        },
        base64ToBlob(text, content_type) {
            var byteString = atob(text);
            var arrayBuffer = new ArrayBuffer(byteString.length);
            var intArray = new Uint8Array(arrayBuffer);

            for (var i = 0; i < byteString.length; i++) {
                intArray[i] = byteString.charCodeAt(i);
            }

            return new Blob([intArray], {
                type: content_type
            });
        }
    },
    setup(){
        return {
            renderUnit
        }
    }
};
